import { Workspace } from '.prisma/client'
import { Archive } from '@mui/icons-material'
import { Button } from '@mui/material'
import {
  DataGrid,
  GridActionsCellItem,
  GridRenderCellParams
} from '@mui/x-data-grid'
import { archiveWorkspaceApi } from 'api_client'
import Router from 'next/router'
interface WorkspaceAdminTableProps {
  workspaces: Workspace[]
  internal_view?: boolean
}

const WorkspaceTable = ({
  workspaces,
  internal_view = false
}: WorkspaceAdminTableProps) => {
  if (internal_view && workspaces.length === 0) {
    return <></>
  }

  async function archiveWorkspace(id: string) {
    if (!confirm('Are you sure you want to archive this workspace?')) return
    const res = await archiveWorkspaceApi(id)
    if (res.status === 200) {
      Router.reload()
    } else {
      alert('Failed to archive')
    }
  }

  var gridColumns: any = [
    {
      field: 'open',
      headerName: '',
      renderCell: (params: GridRenderCellParams) => (
        <Button href={`/workspace/${params.row.id}`}>Open</Button>
      )
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 300
    },
    {
      field: 'store_id',
      headerName: 'Store ID',
      width: 400
    },
    {
      field: 'created_at',
      headerName: 'Created',
      type: 'dateTime',
      valueGetter: ({ value }) => value && new Date(value),
      width: 300
    }
  ]

  if (!internal_view) {
    gridColumns.push({
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: (workspace: any) => {
        return [
          <GridActionsCellItem
            key="archive"
            icon={<Archive sx={{ color: 'gray' }} />}
            label="Edit"
            className="textPrimary"
            onClick={() => archiveWorkspace(workspace.id)}
            color="inherit"
          />
        ]
      }
    })
  }

  return (
    <DataGrid
      autoHeight
      columns={gridColumns}
      rows={workspaces}
      disableSelectionOnClick
    />
  )
}

export default WorkspaceTable

import * as yup from 'yup'

export const workspaceSchema = yup.object().shape({
  name: yup.string().trim().required().min(3).label('Name'),
  store_id: yup
    .string()
    .required()
    .uuid('Please use the appropriate merchant Store ID (UUID)')
    .label('Store ID'),
  offer_amount: yup.number().notRequired(),
  inventory_amount: yup.number().notRequired().nullable(),
  accept_date: yup.string().notRequired()
})

import { FormikValues, useFormik } from 'formik'
import {
  Alert,
  Autocomplete,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  Grid,
  TextField
} from '@mui/material'
import { useEffect, useState } from 'react'
import { postWorkspace } from 'api_client'
import { workspaceSchema } from 'schemas/workspace'
import { postHistoricSales } from 'api_client/sales'

const initialValues: FormikValues = {
  name: '',
  store_id: '',
  offer_amount: 0,
  inventory_amount: 0,
  accept_date: ''
}

export default function NewWorkspaceForm({
  onClose,
  refetch
}: {
  onClose: any
  refetch: () => Promise<void>
}) {
  const [stores, setStores] = useState()

  useEffect(() => {
    ;(async () => {
      if (!stores) {
        try {
          const response = await fetch('/api/offers', {
            method: 'GET'
          })
          const responseJson = await response.json()
          setStores(responseJson.data || [])
        } catch (e: unknown) {
          console.error(e)
        }
      }
    })()
  }, [stores])
  const [error, setError] = useState('')
  const formik = useFormik({
    initialValues,
    validationSchema: workspaceSchema,
    onSubmit: async (values, formikHelpers) => {
      setError('')
      formikHelpers.setSubmitting(true)
      try {
        const { store_id, name, offer_amount, inventory_amount, accept_date } =
          values
        await postWorkspace({
          name,
          store_id,
          offer_amount,
          inventory_amount,
          accept_date
        })
        if (offer_amount > 0) {
          // Populate Historic Sales on Workspace Create
          await postHistoricSales(store_id, accept_date)
        }
        await refetch()
        onClose()
      } catch (e) {
        console.log(e)
        setError('Failed to create workspace')
        throw e
      } finally {
        formikHelpers.setSubmitting(false)
      }
    }
  })
  return (
    <form onSubmit={formik.handleSubmit}>
      <DialogContent>
        {(stores && (
          <Grid container flexDirection="column" rowGap="1em">
            <Autocomplete
              freeSolo={true}
              options={stores}
              getOptionLabel={(option: any) => option.MERCHANT}
              size="small"
              onChange={(event, values: any) => {
                if (values) {
                  formik.setFieldValue('name', values.MERCHANT)
                  formik.setFieldValue('store_id', values.STORE_ID)
                  formik.setFieldValue('offer_amount', values.PRICE)
                  formik.setFieldValue('inventory_amount', values.INVENTORY)
                  formik.setFieldValue('accept_date', values.DATE_OF_RESPONSE)
                }
              }}
              onInputChange={(event, values) => {
                if (event.type !== 'click') {
                  formik.setFieldValue('name', values)
                  formik.setFieldValue('offer_amount', 0)
                  formik.setFieldValue('inventory_amount', 0)
                  formik.setFieldValue('accept_date', '')
                }
              }}
              renderInput={(params) => (
                <TextField
                  variant="standard"
                  error={!!(formik.touched.name && formik.errors.name)}
                  helperText={formik.errors.name}
                  {...params}
                  label="Name"
                />
              )}
            />
            <TextField
              label="Store ID"
              {...formik.getFieldProps('store_id')}
              type="text"
              size="small"
              error={!!(formik.touched.store_url && formik.errors.store_url)}
              helperText={formik.errors.store_id}
              variant="standard"
            />
            {error && <Alert severity="error">{error}</Alert>}
          </Grid>
        )) || (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress size={24} sx={{ mr: 1 }} />
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          type="submit"
          disabled={formik.isSubmitting || !formik.dirty || !formik.isValid}
        >
          {formik.isSubmitting && <CircularProgress size={24} sx={{ mr: 1 }} />}
          Create Workspace
        </Button>
      </DialogActions>
    </form>
  )
}

import { useEffect, useState } from 'react'
import { UseCurrentHookParams } from '../types'

type UseCurrentState<T = any> = {
  isLocal: boolean
  currentValue: T
}

function useCurrent<T>({ initial, local, refetched }: UseCurrentHookParams<T>) {
  const [state, setState] = useState<UseCurrentState<typeof initial>>({
    isLocal: false,
    currentValue: initial
  })

  useEffect(() => {
    if (local && refetched && state.isLocal !== true) {
      setState({ isLocal: true, currentValue: local })
    }
  }, [refetched, local, state])

  return state.currentValue
}

export default useCurrent

import { authenticatedSsr } from 'service/auth/client'

import {
  Invite,
  InviteStatus,
  UserRole,
  Workspace,
  WorkspaceStatus
} from '.prisma/client'

import Head from 'next/head'
import WorkspaceService from 'service/workspace_service'
import { useAuthUser } from 'auth'
import useCurrent from 'hooks/data/useCurrent'
import useWorkspaces from 'hooks/data/useWorkspaces'
import NewWorkspaceModalButton from 'Workspace/NewWorkspaceButton'
import WorkspaceTable from 'Workspace/table'

import { prisma } from 'service/prisma'
import { Button, Stack } from '@mui/material'

export const getServerSideProps = authenticatedSsr(async (user) => {
  const rawWorkspaces = await new WorkspaceService(user).getWorkspaces()
  const rawPendingInvites = await prisma.invite.findMany({
    where: {
      AND: {
        email: {
          in: user.emails
        },
        status: InviteStatus.PENDING
      }
    },
    include: {
      group: {
        select: {
          name: true
        }
      }
    }
  })

  if (user.role === UserRole.MERCHANT) {
    if (rawPendingInvites.length === 0 && rawWorkspaces.length === 1) {
      return {
        redirect: {
          destination: `/workspace/${rawWorkspaces[0].id}/overview/home`,
          permanent: false
        }
      }
    }
  }
  if (user.role === UserRole.PARTNER) {
    if (rawPendingInvites?.length === 0 && rawWorkspaces?.length === 1) {
      return {
        redirect: {
          destination: `/workspace/${rawWorkspaces[0].parent_workspace_id}/partner/${rawWorkspaces[0].id}/intro`,
          permanent: false
        }
      }
    }
  }
  const workspaces = JSON.parse(JSON.stringify(rawWorkspaces))
  const pending_invites = JSON.parse(JSON.stringify(rawPendingInvites))

  return {
    props: {
      workspaces,
      pending_invites
    }
  }
})

type InviteWithGroupName = Invite & {
  group: {
    name: string
  }
}

type HomeProps = {
  workspaces: Workspace[]
  pending_invites: InviteWithGroupName[]
}

export default function Home({ workspaces, pending_invites }: HomeProps) {
  const { data, refetch, refetched } = useWorkspaces()
  const user = useAuthUser()
  const currentWorkspaces = useCurrent<Workspace[]>({
    initial: workspaces,
    local: data,
    refetched
  })

  return (
    <>
      <Head>
        <title>Sell Dashboard</title>
      </Head>
      <div>
        <h1>
          Sell Dashboard <NewWorkspaceModalButton refetch={refetch} />
        </h1>
        <WorkspaceTable
          workspaces={currentWorkspaces.filter(
            (workspace) => workspace.status != WorkspaceStatus.ARCHIVED
          )}
          internal_view={user.role === UserRole.MERCHANT}
        />
      </div>
      <Stack>
        {pending_invites.map((i) => (
          <Button href={`/auth/invite/${i.id}`} key={i.id}>
            Invitation for {i.group.name}
          </Button>
        ))}
      </Stack>
    </>
  )
}

import { Add } from '@mui/icons-material'
import { Button } from '@mui/material'
import { UserRole } from '@prisma/client'
import { useAuthUser } from 'auth'
import DialogWithClose from 'components/DialogWithClose'
import { useState } from 'react'

import NewWorkspaceForm from './NewWorkspaceForm'

export default function NewWorkspaceModalButton({ refetch }: { refetch: any }) {
  const [open, setOpen] = useState(false)
  const user = useAuthUser()

  if (user.role === UserRole.MERCHANT) {
    return <></>
  }
  const onClose = () => setOpen(false)
  return (
    <>
      <DialogWithClose
        title="Create New Workspace"
        open={open}
        onClose={onClose}
      >
        <NewWorkspaceForm onClose={onClose} refetch={refetch} />
      </DialogWithClose>
      <Button
        variant="contained"
        size="small"
        color="primary"
        aria-label="add"
        onClick={() => setOpen(true)}
      >
        <Add sx={{ mr: 1 }} /> Create Workspace
      </Button>
    </>
  )
}

import { Workspace } from '.prisma/client'
import { useEffect, useState } from 'react'
import { listWorkspaces } from 'api_client'
import { isAxiosError } from 'utils'
import { DataHook, DataHookState } from 'hooks/data/types'

const INITIAL_STATE: DataHookState<Workspace[]> = {
  loading: true,
  data: null,
  error: null,
  refetched: false
}

const useWorkspaces: DataHook<Workspace[]> = (id) => {
  const [state, setState] = useState(INITIAL_STATE)

  useEffect(() => {
    ;(async () => {
      try {
        const { data } = await listWorkspaces()
        setState((prevState) => ({ ...prevState, data }))
      } catch (e: unknown) {
        const error = isAxiosError(e) ? e : new Error(JSON.stringify(e))
        setState((prevState) => ({ ...prevState, error }))
      }
    })()
  }, [id])

  const refetch = async () => {
    try {
      const { data } = await listWorkspaces()
      setState((prevState) => ({ ...prevState, refetched: true, data }))
    } catch (e: unknown) {
      const error = isAxiosError(e) ? e : new Error(JSON.stringify(e))
      setState((prevState) => ({ ...prevState, error }))
    }
  }

  return { ...state, refetch }
}

export default useWorkspaces

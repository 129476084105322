import {
  Dialog,
  DialogProps,
  DialogTitle,
  IconButton,
  Theme
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

interface DialogWithCloseProps extends DialogProps {
  title?: string
}

const DialogWithClose = (props: DialogWithCloseProps) => (
  <Dialog fullWidth={true} {...props}>
    <DialogTitle>
      {props.title}
      {props.onClose ? (
        <IconButton
          aria-label="close"
          onClick={() => props.onClose && props.onClose({}, 'escapeKeyDown')}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme: Theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
    {props.children}
  </Dialog>
)

export default DialogWithClose
